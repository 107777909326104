$primary-dark: darken($turquoise, 10%) !default;

$info: darken($cyan, 10%) !default;
$success: darken($green, 10%) !default;
$warning: darken($yellow, 10%) !default;
$danger: darken($red, 10%) !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$orange-invert: findColorInvert($orange) !default;
$yellow-invert: findColorInvert($yellow) !default;
$green-invert: findColorInvert($green) !default;
$turquoise-invert: findColorInvert($turquoise) !default;
$cyan-invert: findColorInvert($cyan) !default;
$blue-invert: findColorInvert($blue) !default;
$purple-invert: findColorInvert($purple) !default;
$red-invert: findColorInvert($red) !default;

$primary-invert: $turquoise-invert !default;
$primary-invert-dark: darken($turquoise-invert, 10%) !default;
$info-invert: $cyan-invert !default;
$success-invert: $green-invert !default;
$warning-invert: $yellow-invert !default;
$danger-invert: $red-invert !default;
$light-invert: $dark !default;
$dark-invert: $light !default;

// General colors

$background: $white-ter !default;
$background-dark: $black-ter !default;

$border-dark: $grey-darker !default;
$border-hover-dark: $grey-dark !default;

// Text colors

$text-dark: $grey-light !default;
$text-invert: findColorInvert($text) !default;
$text-invert-dark: findColorInvert($text-dark) !default;
$text-light: $grey !default;
$text-strong-dark: $grey-lighter !default;

// Code colors

$code-dark: darken($red, 15%) !default;
$code-background-dark: $background-dark !default;

$pre-dark: $text-dark !default;
$pre-background-dark: $background-dark !default;

// Link colors

$link-dark: $blue-light !default;
$link-invert-dark: $blue-invert !default;

$link-hover-dark: $grey-lighter !default;
$link-hover-border-dark: $grey-dark !default;

$link-focus-dark: $grey-lighter !default;
$link-focus-border-dark: $blue-light !default;

$link-active-dark: $grey-lighter !default;
$link-active-border-dark: $grey-light !default;
