$box-color-dark: $text-dark !default;
$box-background-color-dark: $black !default;
$box-shadow-dark: 0 2px 3px rgba($white, 0.1), 0 0 0 1px rgba($white, 0.1) !default;

$box-link-hover-shadow-dark: 0 2px 3px rgba($white, 0.1), 0 0 0 1px $link-dark !default;
$box-link-active-shadow-dark: inset 0 1px 2px rgba($white, 0.2), 0 0 0 1px $link-dark !default;

.box {
  background-color: $box-background-color-dark;
  box-shadow: $box-shadow-dark;
  color: $box-color-dark; }

a.box {
  &:hover,
  &:focus {
    box-shadow: $box-link-hover-shadow-dark; }
  &:active {
    box-shadow: $box-link-active-shadow-dark; } }
