// Main container

.hero {
  // Colors
  @each $name, $pair in $colors {
    $color: darken(nth($pair, 1), 10%);
    $color-invert: nth($pair, 2);
    &.is-#{$name}, &.is-#{$name}-dark {
      background-color: $color;
      color: $color-invert;
      a:not(.button):not(.dropdown-item):not(.tag),
      strong {
        color: inherit; }
      .title {
        color: $color-invert; }
      .subtitle {
        color: rgba($color-invert, 0.9);
        a:not(.button),
        strong {
          color: $color-invert; } }
      .navbar-menu {
        @include touch {
          background-color: $color; } }
      .navbar-item,
      .navbar-link {
        color: rgba($color-invert, 0.7); }
      a.navbar-item,
      .navbar-link {
        &:hover,
        &.is-active {
          background-color: darken($color, 5%);
          color: $color-invert; } }
      .tabs {
        a {
          color: $color-invert;
          opacity: 0.9;
          &:hover {
            opacity: 1; } }
        li {
          &.is-active a {
            opacity: 1; } }
        &.is-boxed,
        &.is-toggle {
          a {
            color: $color-invert;
            &:hover {
              background-color: rgba($black, 0.1); } }
          li.is-active a {
            &,
            &:hover {
              background-color: $color-invert;
              border-color: $color-invert;
              color: $color; } } } }
      // Modifiers
      &.is-bold {
        $gradient-top-left: darken(saturate(adjust-hue($color, -10deg), 10%), 10%);
        $gradient-bottom-right: lighten(saturate(adjust-hue($color, 10deg), 5%), 5%);
        background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%);
        @include mobile {
          .navbar-menu {
            background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%); } } }
      // Responsiveness
      // +mobile
      //   .nav-toggle
      //     span
      //       background-color: $color-invert
      //     &:hover
      //       background-color: rgba($black, 0.1)
      //     &.is-active
      //       span
      //         background-color: $color-invert
      //   .nav-menu
      //     .nav-item
 } }      //       border-top-color: rgba($color-invert, 0.2)

  // Colors dark
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name}-dark {
      background-color: $color;
      color: $color-invert;
      a:not(.button):not(.dropdown-item):not(.tag),
      strong {
        color: inherit; }
      .title {
        color: $color-invert; }
      .subtitle {
        color: rgba($color-invert, 0.9);
        a:not(.button),
        strong {
          color: $color-invert; } }
      .navbar-menu {
        @include touch {
          background-color: $color; } }
      .navbar-item,
      .navbar-link {
        color: rgba($color-invert, 0.7); }
      a.navbar-item,
      .navbar-link {
        &:hover,
        &.is-active {
          background-color: darken($color, 5%);
          color: $color-invert; } }
      .tabs {
        a {
          color: $color-invert;
          opacity: 0.9;
          &:hover {
            opacity: 1; } }
        li {
          &.is-active a {
            opacity: 1; } }
        &.is-boxed,
        &.is-toggle {
          a {
            color: $color-invert;
            &:hover {
              background-color: rgba($black, 0.1); } }
          li.is-active a {
            &,
            &:hover {
              background-color: $color-invert;
              border-color: $color-invert;
              color: $color; } } } }
      // Modifiers
      &.is-bold {
        $gradient-top-left: darken(saturate(adjust-hue($color, -10deg), 10%), 10%);
        $gradient-bottom-right: lighten(saturate(adjust-hue($color, 10deg), 5%), 5%);
        background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%);
        @include mobile {
          .navbar-menu {
            background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%); } } }
      // Responsiveness
      // +mobile
      //   .nav-toggle
      //     span
      //       background-color: $color-invert
      //     &:hover
      //       background-color: rgba($black, 0.1)
      //     &.is-active
      //       span
      //         background-color: $color-invert
      //   .nav-menu
      //     .nav-item
 } } }      //       border-top-color: rgba($color-invert, 0.2)
