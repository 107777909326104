$button-color-dark: $grey-lighter !default;
$button-background-color-dark: $black !default;

$button-border-color-dark: $grey-darker !default;

$button-hover-color-dark: $link-hover-dark !default;
$button-hover-border-color-dark: $link-hover-border-dark !default;

$button-focus-color-dark: $link-focus-dark !default;
$button-focus-border-color-dark: $link-focus-border-dark !default;
$button-focus-box-shadow-size: 0 0 0 0.125em !default;
$button-focus-box-shadow-color-dark: rgba($link-dark, 0.25) !default;

$button-active-color-dark: $link-active-dark !default;
$button-active-border-color-dark: $link-active-border-dark !default;

$button-text-color-dark: $text-dark !default;
$button-text-hover-background-color-dark: $background-dark !default;
$button-text-hover-color-dark: $text-strong-dark !default;

$button-disabled-background-color-dark: $black !default;
$button-disabled-border-color-dark: $grey-darker !default;

$button-static-color-dark: $grey !default;
$button-static-background-color-dark: $white-ter !default;
$button-static-border-color-dark: $grey-darker !default;

.button {
  background-color: $button-background-color-dark;
  border-color: $button-border-color-dark;
  color: $button-color-dark;
  // States
  &:hover,
  &.is-hovered {
    border-color: $button-hover-border-color-dark;
    color: $button-hover-color-dark; }
  &:focus,
  &.is-focused {
    border-color: $button-focus-border-color-dark;
    color: $button-focus-color-dark;
    &:not(:active) {
      box-shadow: $button-focus-box-shadow-size $button-focus-box-shadow-color-dark; } }
  &:active,
  &.is-active {
    border-color: $button-active-border-color-dark;
    color: $button-active-color-dark; }
  // Colors
  &.is-text {
    color: $button-text-color-dark;
    &:hover,
    &.is-hovered,
    &:focus,
    &.is-focused {
      background-color: $button-text-hover-background-color-dark;
      color: $button-text-hover-color-dark; }
    &:active,
    &.is-active {
      background-color: darken($button-text-hover-background-color-dark, 5%);
      color: $button-text-hover-color-dark; } }
  // Colors
  @each $name, $pair in $colors {
    $color: darken(nth($pair, 1), 10%);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      border-color: transparent;
      color: $color-invert;
      &:hover,
      &.is-hovered {
        background-color: darken($color, 2.5%);
        border-color: transparent;
        color: $color-invert; }
      &:focus,
      &.is-focused {
        border-color: transparent;
        color: $color-invert;
        &:not(:active) {
          box-shadow: $button-focus-box-shadow-size rgba($color, 0.25); } }
      &:active,
      &.is-active {
        background-color: darken($color, 5%);
        border-color: transparent;
        color: $color-invert; }
      &[disabled],
      fieldset[disabled] & {
        background-color: $color;
        border-color: transparent;
        box-shadow: none; }
      &.is-inverted {
        background-color: $color-invert;
        color: $color;
        &:hover {
          background-color: darken($color-invert, 5%); }
        &[disabled],
        fieldset[disabled] & {
          background-color: $color-invert;
          border-color: transparent;
          box-shadow: none;
          color: $color; } }
      &.is-loading {
        &::after {
          border-color: transparent transparent $color-invert $color-invert !important; } }
      &.is-outlined {
        background-color: transparent;
        border-color: $color;
        color: $color;
        &:hover,
        &:focus {
          background-color: $color;
          border-color: $color;
          color: $color-invert; }
        &.is-loading {
          &::after {
            border-color: transparent transparent $color $color !important; } }
        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color;
          box-shadow: none;
          color: $color; } }
      &.is-inverted.is-outlined {
        background-color: transparent;
        border-color: $color-invert;
        color: $color-invert;
        &:hover,
        &:focus {
          background-color: $color-invert;
          color: $color; }
        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color-invert;
          box-shadow: none;
          color: $color-invert; } } } }
  // Colors Dark
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name}-dark {
      background-color: $color;
      border-color: transparent;
      color: $color-invert;
      &:hover,
      &.is-hovered {
        background-color: darken($color, 2.5%);
        border-color: transparent;
        color: $color-invert; }
      &:focus,
      &.is-focused {
        border-color: transparent;
        color: $color-invert;
        &:not(:active) {
          box-shadow: $button-focus-box-shadow-size rgba($color, 0.25); } }
      &:active,
      &.is-active {
        background-color: darken($color, 5%);
        border-color: transparent;
        color: $color-invert; }
      &[disabled],
      fieldset[disabled] & {
        background-color: $color;
        border-color: transparent;
        box-shadow: none; }
      &.is-inverted {
        background-color: $color-invert;
        color: $color;
        &:hover {
          background-color: darken($color-invert, 5%); }
        &[disabled],
        fieldset[disabled] & {
          background-color: $color-invert;
          border-color: transparent;
          box-shadow: none;
          color: $color; } }
      &.is-loading {
        &::after {
          border-color: transparent transparent $color-invert $color-invert !important; } }
      &.is-outlined {
        background-color: transparent;
        border-color: $color;
        color: $color;
        &:hover,
        &:focus {
          background-color: $color;
          border-color: $color;
          color: $color-invert; }
        &.is-loading {
          &::after {
            border-color: transparent transparent $color $color !important; } }
        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color;
          box-shadow: none;
          color: $color; } }
      &.is-inverted.is-outlined {
        background-color: transparent;
        border-color: $color-invert;
        color: $color-invert;
        &:hover,
        &:focus {
          background-color: $color-invert;
          color: $color; }
        &[disabled],
        fieldset[disabled] & {
          background-color: transparent;
          border-color: $color-invert;
          box-shadow: none;
          color: $color-invert; } } } }
  // Modifiers
  &[disabled],
  fieldset[disabled] & {
    background-color: $button-disabled-background-color-dark;
    border-color: $button-disabled-border-color-dark; }
  &.is-static {
    background-color: $button-static-background-color-dark;
    border-color: $button-static-border-color-dark;
    color: $button-static-color-dark; } }
