$title-color-dark: $grey-lighter !default;

$subtitle-color-dark: $grey-light !default;
$subtitle-strong-color-dark: $grey-lighter !default;

.title {
  color: $title-color-dark; }

.subtitle {
  color: $subtitle-color-dark;
  strong {
    color: $subtitle-strong-color-dark; } }
