$body-background-color-dark: $body-background-dark !default;
$body-color-dark: $text-dark !default;

$hr-background-color-dark: $background-dark !default;

$strong-color-dark: $text-strong-dark !default;

html {
  background-color: $body-background-color-dark; }

body {
  color: $body-color-dark; }

// Inline

a {
  color: $link-dark;
  &:hover {
    color: $link-hover-dark; } }

code {
  background-color: $code-background-dark;
  color: $code-dark; }

hr {
  background-color: $hr-background-color-dark; }

strong {
  color: $strong-color-dark; }

// Block

pre {
  background-color: $pre-background-dark;
  color: $pre-dark; }

table {
  th {
    color: $text-strong-dark; } }
