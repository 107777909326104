$modal-background-background-color-dark: rgba($white, 0.86) !default;

$modal-card-head-background-color-dark: $background-dark !default;
$modal-card-head-border-bottom-dark: 1px solid $border-dark !default;

$modal-card-title-color-dark: $text-strong-dark !default;

$modal-card-foot-border-top-dark: 1px solid $border-dark !default;

$modal-card-body-background-color-dark: $white !default;

.modal-background {
  background-color: $modal-background-background-color-dark; }

.modal-card-head,
.modal-card-foot {
  background-color: $modal-card-head-background-color-dark; }

.modal-card-head {
  border-bottom: $modal-card-head-border-bottom-dark; }

.modal-card-title {
  color: $modal-card-title-color-dark; }

.modal-card-foot {
  border-top: $modal-card-foot-border-top-dark; }

.modal-card-body {
  @include overflow-touch;
  background-color: $modal-card-body-background-color-dark; }
